import {
  CancelEventSeries,
  DraftEventSeries,
  UpdateEventSeriesSchedule,
  AddEventSeriesAgenda,
  AddEventSubscription,
  RemoveEventSeriesAgenda,
  RemoveEventSubscription,
  PublishEventSeries,
  UpdateEventSeriesPhysicalLocation,
  UpdateEventSeriesVirtualLocation,
  UpdateEventSeriesDescription,
  UploadEventSeriesPhoto,
  UploadEventSeriesGalleryPhoto,
  DeleteEventSeriesGalleryPhoto,
  GenerateQRCode,
  FeatureEventSeries,
  UnfeatureEventSeries,
  MapMeetupEventSeries,
  ActivateMeetupEventSeriesExport,
  DeactivateMeetupEventSeriesExport,
  CheckIn,
  Rsvp,
  UnRsvp
} from "./operations.gql";
import * as BE from "@/utils/api/backendException.js";
import { hasuraClient } from "@/utils/graphql/apollo.js";
// import { Logger, DEBUG } from "@/utils/plugins/logging";
import { call } from "@/utils/api/base.js";

// const $log = new Logger("SeriesApi", { level: DEBUG });

export async function cancelEventSeries(seriesId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Event Series not found."],
    [BE.NOT_PUBLISHED, "Event Series is not yet published."],
    [BE.PERMISSION, "User does not have permission to modify Series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error canceling the event series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: CancelEventSeries,
        variables: {
          eventSeriesId: seriesId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

function formattedDateTime(beginDate, startTime) {
  return `${beginDate}T${startTime}`;
}

export async function draftEventSeries(chapterId, beginDate, startTime, durationMinutes, weeklyFrequency, timeZoneId, organizerUserIds) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NUMBER_TOO_SMALL, "Duration or weekly frequency is not valid."],
    [BE.NOT_EQUAL, "Please specify a start date."],
    [BE.INVALID_ARGS, "Time zone is not valid."],
    [BE.NOT_EXIST, "This series was not found."],
    [BE.NOT_BELONG, "No longer applicable."],
    [BE.PERMISSION, "You don't have permission to update this series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error creating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DraftEventSeries,
        variables: {
          chapterId, //string
          beginDate: formattedDateTime(beginDate, startTime), //string
          durationMinutes, //int
          weeklyFrequency, //int
          timeZoneId, //string
          organizerUserIds //string[]
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function updateEventSeriesSchedule(eventSeriesId, beginDate, startTime, durationMinutes, weeklyFrequency, timeZoneId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NUMBER_TOO_SMALL, "Duration or weekly frequency is not valid."],
    [BE.NOT_EQUAL, "Please specify a start date."],
    [BE.INVALID_ARGS, "Time zone is not valid."],
    [BE.NOT_EXIST, "This series was not found."],
    [BE.PERMISSION, "You don't have permission to update this series."],
    [BE.ALREADY_PUBLISHED, "Failed to update. This series has already been published."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateEventSeriesSchedule,
        variables: {
          eventSeriesId: eventSeriesId,
          beginDate: formattedDateTime(beginDate, startTime),
          durationMinutes,
          weeklyFrequency,
          timeZoneId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function addEventSubscription(eventSeriesId, userId, isOrganizer) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EQUAL, "You don't have permission to update this series."],
    [BE.NOT_EXIST, "Error updating this series. The user or series does not exist."],
    [BE.PERMISSION, "You don't have permission to update this series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error adding organizer to this series."],
    [BE.UPDATE_FAILED, "Error updating organizer in this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: AddEventSubscription,
        variables: {
          eventSeriesId,
          userId,
          isOrganizer
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function removeEventSubscription(eventSeriesId, userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EQUAL, "You don't have permission to update this series."],
    [BE.NOT_EXIST, "Error updating this series. The user or series does not exist."],
    [BE.PERMISSION, "You don't have permission to update this series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETE_FAILED, "Error removing organizer from this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: RemoveEventSubscription,
        variables: {
          eventSeriesId,
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function publishEventSeries(eventSeriesId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This series was not found."],
    [BE.PERMISSION, "You don't have permission to create this series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: PublishEventSeries,
        variables: {
          eventSeriesId: eventSeriesId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function updateEventSeriesPhysicalLocation(eventSeriesId, lat, lng, physicalAddress, physicalTitle) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This location does not exist."],
    [BE.PERMISSION, "You don't have permission to update this series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateEventSeriesPhysicalLocation,
        variables: {
          eventSeriesId,
          lat,
          lng,
          physicalAddress,
          physicalTitle
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function updateEventSeriesVirtualLocation(eventSeriesId, virtualUrl, virtualMeetingId, virtualMeetingPassword, isGlobal) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This is not a valid URL."],
    [BE.PERMISSION, "You don't have permission to create this series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateEventSeriesVirtualLocation,
        variables: {
          eventSeriesId,
          virtualUrl,
          virtualMeetingId,
          virtualMeetingPassword,
          isGlobal
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function updateEventSeriesDescription(eventSeriesId, title, descriptionJson, rsvpLimit) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NUMBER_TOO_SMALL, "The RSVP limit cannot be negative."],
    [BE.NOT_EXIST, "This series was not found."],
    [BE.PERMISSION, "You don't have permission to create this series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "All fields must be filled out."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UpdateEventSeriesDescription,
        variables: {
          eventSeriesId,
          title,
          descriptionJson,
          rsvpLimit //int
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function uploadEventSeriesPhoto(eventSeriesId, photoBase64Image) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "File missing. Please upload your photo."],
    [BE.INVALID_FORMAT, "File format was invalid."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "All fields must be filled out."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UploadEventSeriesPhoto,
        variables: {
          eventSeriesId,
          photoBase64Image
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function uploadEventSeriesGalleryPhoto(eventSeriesId, photoBase64Image) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "File missing. Please upload your photo."],
    [BE.NOT_EXIST, "Event Series does not exist."],
    [BE.INVALID_FORMAT, "File format was invalid."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Upload failed."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UploadEventSeriesGalleryPhoto,
        variables: {
          eventSeriesId,
          photoBase64Image
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function deleteEventSeriesGalleryPhoto(eventSeriesGalleryId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "Missing arguments."],
    [BE.NOT_EXIST, "Event Series does not exist."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DELETE_FAILED, "Delete failed."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DeleteEventSeriesGalleryPhoto,
        variables: {
          eventSeriesGalleryId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function addEventSeriesAgenda(eventSeriesId, timeAt, descriptionText) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Event Series does not exist."],
    [BE.INVALID_FORMAT, "Time format was invalid."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: AddEventSeriesAgenda,
        variables: {
          eventSeriesId,
          timeAt,
          descriptionText
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function removeEventSeriesAgenda(eventSeriesId, timeAt) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Event Series does not exist."],
    [BE.INVALID_FORMAT, "Time format was invalid."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: RemoveEventSeriesAgenda,
        variables: {
          eventSeriesId,
          timeAt
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function generateQRCode(eventSeriesId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This series was not found."],
    [BE.PERMISSION, "You don't have permission to generate a QR code for this series."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.UPLOAD_FAILED, "Error uploading QR code."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: GenerateQRCode,
        variables: {
          eventSeriesId: eventSeriesId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function featureEventSeries(eventSeriesId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This series was not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: FeatureEventSeries,
        variables: {
          eventSeriesId: eventSeriesId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function unfeatureEventSeries(eventSeriesId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "This series was not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error updating this series."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UnfeatureEventSeries,
        variables: {
          eventSeriesId: eventSeriesId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function mapMeetupEventSeries(eventSeriesId, meetupPhysicalImageId, meetupVirtualImageId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out (or none at all)."],
    [BE.NOT_EXIST, "This series was not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error inserting this map."],
    [BE.UPDATE_FAILED, "Error updating this map."],
    [BE.DELETE_FAILED, "Error deleting this map."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: MapMeetupEventSeries,
        variables: {
          eventSeriesId: eventSeriesId,
          meetupPhysicalImageId: meetupPhysicalImageId,
          meetupVirtualImageId: meetupVirtualImageId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function activateMeetupEventSeriesExport(eventSeriesId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out (or none at all)."],
    [BE.NOT_EXIST, "This series was or its map not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error activating."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: ActivateMeetupEventSeriesExport,
        variables: {
          eventSeriesId: eventSeriesId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function deactivateMeetupEventSeriesExport(eventSeriesId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out (or none at all)."],
    [BE.NOT_EXIST, "This series was or its map not found."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.UPDATE_FAILED, "Error deactivating."],
    [BE.HISTORY_SUCCESS_FAILED, "The information failed to save to the database successfully. Please try again."],
    [BE.HISTORY_FAILURE_FAILED, "The information failed to save to the database. Please try again."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: DeactivateMeetupEventSeriesExport,
        variables: {
          eventSeriesId: eventSeriesId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function checkIn(eventOccurrenceId, userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Event or user not found."],
    [BE.CANCELLED, "This event has been cancelled."],
    [BE.TOO_EARLY, "You cannot check in to this event because it is too early."],
    [BE.TOO_LATE, "You cannot check in to this event because it is too late."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error checking in to the event."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: CheckIn,
        variables: {
          eventOccurrenceId,
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function rsvp(eventOccurrenceId, userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Event or user not found."],
    [BE.CANCELLED, "This event has been cancelled."],
    [BE.TOO_LATE, "You cannot RSVP to this event because it is too late."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error adding RSVP."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: Rsvp,
        variables: {
          eventOccurrenceId,
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}

export async function unrsvp(eventOccurrenceId, userId) {
  const validationReasonMap = new Map([
    [BE.EMPTY_FIELD, "All required fields must be filled out."],
    [BE.NOT_EXIST, "Event or user not found."],
    [BE.CANCELLED, "This event has been cancelled."],
    [BE.TOO_LATE, "You cannot unRSVP to this event because it is too late."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const executionReasonMap = new Map([
    [BE.INSERT_FAILED, "Error removing RSVP."],
    [BE.DEFAULT_FALLBACK, BE.fallbackErrorMessage]
  ]);
  const response = await call(
    () =>
      hasuraClient.mutate({
        mutation: UnRsvp,
        variables: {
          eventOccurrenceId,
          userId
        }
      }),
    validationReasonMap,
    executionReasonMap
  );
  return response;
}