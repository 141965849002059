<script>
import { SuawMainContent, SuawForm, SuawHeading, SuawTextInput, SuawFileUpload, SuawButton, SuawSummary, SuawTextArea, SuawInputGroup } from "@suaw/suaw-component-library";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import SeriesEditFormButtons from "./SeriesEditFormButtons.vue";
import * as SeriesApi from "../../api.js";
import * as EventApi from "../../../events/api"
import { convertFileToBase64String } from "@/utils/formatting/files.js";

export default {
  name: "SeriesDescription",
  components: {
    SuawMainContent,
    SuawForm,
    SuawHeading,
    SuawTextInput,
    SuawFileUpload,
    SuawButton,
    SuawSummary,
    SuawTextArea,
    SuawInputGroup,
    SeriesEditFormButtons
  },
  props: {
    series: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      seriesDescriptionForm: {
        eventSeriesId: this.$route.params.seriesId,
        title: this.series.title,
        descriptionJson: this.series.description_json ?? {
          type: "doc",
          content: [
            {
              type: "paragraph",
              attrs: {
                textAlign: "left"
              },
              content: [
                {
                  text:
                    "Commit to your writing every week. In-person events take place in public spaces where members come together to make progress. No critiques, read-alouds, or distractions: just a session of focused writing. If you like, stick around to socialize afterwards and make connections with your fellow writers. You've found your writing group - join us!",
                  type: "text"
                }
              ]
            }
          ]
        },
        rsvpLimit: this.series.rsvp_limit,
        photoUrl: ""
      }
    };
  },
  computed: {
    publishedEdit() {
      return this.series && !this.series.is_draft;
    },
    summaryHeading() {
      if (!this.series || this.series?.is_draft) return "Create New Series";
      return "Edit Series";
    }
  },
  validations: {
    seriesDescriptionForm: {
      title: { required },
      descriptionJson: { required }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async convertFile(file) {
      try {
        const base64Data = await convertFileToBase64String(file);
        this.seriesDescriptionForm.photoUrl = base64Data;
      } catch (error) {
        this.$root.$emit("universal-error-message", "Error converting file to Base64");
      }
    },
    onPrev() {
      this.$emit("next-step", { step: "location" });
    },
    async updateEventSeriesDescription() {
      const { eventSeriesId, title, descriptionJson, rsvpLimit } = this.seriesDescriptionForm;
      const result = await SeriesApi.updateEventSeriesDescription(eventSeriesId, title, descriptionJson, parseInt(rsvpLimit));
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async updateEventOccurrenceDescription() {
      const { title, descriptionJson, rsvpLimit } = this.seriesDescriptionForm;
      const result = await EventApi.updateEventOccurrenceDescription(this.series.upcoming_event[0].id, title, descriptionJson, parseInt(rsvpLimit));
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async uploadEventSeriesPhoto() {
      const { eventSeriesId, photoUrl } = this.seriesDescriptionForm;
      const result = await SeriesApi.uploadEventSeriesPhoto(eventSeriesId, photoUrl);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSeriesDescriptionSubmit() {
      this.$v.seriesDescriptionForm.$touch();
      if (this.$v.seriesDescriptionForm.$invalid) {
        this.$root.$emit("universal-error-message", "The series description form is invalid.");
        return;
      }

      const updateEventSeriesDescriptionResult = await this.updateEventSeriesDescription();
      if (!updateEventSeriesDescriptionResult.success) {
        return;
      }

      if (this.publishedEdit) {
        const updateEventOccurrenceDescriptionResult = await this.updateEventOccurrenceDescription();
        if (!updateEventOccurrenceDescriptionResult.success) {
          return;
        }
      }

      if (!this.series.photo_url || this.seriesDescriptionForm.photoUrl) {
        const uploadEventSeriesPhotoResult = await this.uploadEventSeriesPhoto();
        if (!uploadEventSeriesPhotoResult.success) {
          return;
        }
      }

      if (this.publishedEdit) {
        this.$root.$emit("universal-success-message", "Your series has been edited successfully!");
        this.$router.push({
          name: "Series",
          params: {
            chapterSlug: this.series.chapter.slug,
            eventId: this.series.upcoming_event[0].id,
            seriesSlug: this.series.slug
          }
        });
      } else {
        const publishEventSeriesResult = await this.publishEventSeries();
        if (publishEventSeriesResult.success) {
          const eventId = publishEventSeriesResult.result?.id;
          this.$router.push({
            name: "Series",
            params: {
              chapterSlug: this.series.chapter.slug,
              eventId: eventId,
              seriesSlug: this.series.slug
            }
          });
          this.$root.$emit("universal-success-message", "Your series has been published successfully!");
        }
      }
    },
    async publishEventSeries() {
      const result = await SeriesApi.publishEventSeries(this.series.id);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <SuawForm>
      <template #form>
        <SuawSummary summary-type="text" :heading="summaryHeading" />
        <SuawHeading level="4" content="Details" />
        <SuawTextInput
          id="title-series-description"
          v-model="seriesDescriptionForm.title"
          type="text"
          label="Title"
          is-required
          :state="formFieldState($v, 'seriesDescriptionForm', 'title')"
          :error-message="validateErrors($v.seriesDescriptionForm.title, 'Series name')"
          @blur="$v.seriesDescriptionForm.title.$touch()"
        />
        <SuawTextArea
          id="eventDesc"
          v-model="seriesDescriptionForm.descriptionJson"
          :use-tip-tap="true"
          label="Description"
        />
        <SuawFileUpload upload-title="Upload Series Image" @file-selected="convertFile" />
        <SuawTextInput id="eventRSVP" v-model="seriesDescriptionForm.rsvpLimit" type="number" :min="5" label="RSVP Limit (Optional)" placeholder="No RSVP Limit" />
      </template>
    </SuawForm>
    <SeriesEditFormButtons v-if="publishedEdit" @cancel="$emit('cancel')" @back="$emit('back')" @confirm="onSeriesDescriptionSubmit" />
    <SuawInputGroup v-else field-one-size="0" field-two-size="0" group-gap="octuple" :centered="true">
      <SuawButton class="suaw-button--no-x-pd" size="large" type="ghost" icon-left="IconCircleChevronLeft" button-text="Prev" @click="onPrev" />
      <SuawButton size="large" type="primary" icon-right="" button-text="Publish Series" @click="onSeriesDescriptionSubmit" />
    </SuawInputGroup>
  </SuawMainContent>
</template>
